@tailwind base;
@tailwind components;
@tailwind utilities;
/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
h1, h2, h3, h4, h5, h6 {
    @apply font-sans;
    @apply font-bold;
    @apply mt-8;
    @apply mb-4;
}

h1, h2 {
    @apply leading-snug;
}

h3, h4, h5, h6 {
    @apply leading-relaxed;
}

h1 { @apply text-3xl; }
h2 { @apply text-2xl; }
h3 { @apply text-xl; }
h4 { @apply text-lg; }
